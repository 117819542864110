<template>
  <div
    :class="outerBg"
    class="tw-grid tw-h-[40px] tw-w-[40px] tw-flex-none tw-place-content-center tw-rounded-full"
  >
    <div
      :class="innerBg"
      class="tw-grid tw-h-[30px] tw-w-[30px] tw-place-content-center tw-rounded-full"
    >
      <div :class="textColor" class="tw-grid tw-place-content-center">
        <AppIcon :name="icon" size="16" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  icon: { type: String, default: "" },
  theme: { type: String, default: "gray" },
});

const outerBg = computed(() => `tw-bg-${props.theme}-50`);
const innerBg = computed(() => `tw-bg-${props.theme}-100`);
const textColor = computed(() => `tw-text-${props.theme}-600`);
</script>

<style scoped></style>
